import React from "react";
import FiltersProvider from "./src/context/FiltersContext";

export const onClientEntry = async () => {
  require("babel-polyfill");
  require("whatwg-fetch");

  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }
};

export const wrapRootElement = ({ element }) => (
  <FiltersProvider>{element}</FiltersProvider>
);
